$tab-color:#379683;

$footer-color: #212529;
$header-color: #212529;
$card-color: rgba(33,37,41,0.8); /*#212529;*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


    background: url('/assets/images/bg-index-1a.jpg') no-repeat fixed;
    background-size: cover;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}
body {
  margin-bottom: 60px; /* Margin bottom by footer height */
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  /* line-height: 60px;*/ /* Vertically center the text there */
  background-color: $footer-color;
  color:#fff;
}

.header-bg{
  background-color: $header-color;
}

/* Custom page CSS
-------------------------------------------------- */
/* Not required for template or sticky footer method. */
footer .list-inline {
  margin: 0;
  padding: 0;
}
footer .copyright {
  font-size: 14px;
  text-align: center;
  margin-bottom: 0;
  padding:15px;
}


nav > .nav.nav-tabs{

  border: none;
  color:#fff;
  background:#272e38;
  border-radius:0;

}
nav > div a.nav-item.nav-link,
nav > div a.nav-item.nav-link.active
{
  border: none;
  padding: 18px 25px;
  color:#fff;
  background:#272e38;
  border-radius:0;
}

nav > div a.nav-item.nav-link.active
{
  background: $tab-color;
}

nav > div a.nav-item.nav-link.active:after
{
  content: "";
  position: relative;
  bottom: -60px;
  left: -10%;
  border: 15px solid transparent;
  border-top-color:$tab-color ;
}
.tab-content{
  background: #454d55;
  line-height: 25px;
  border: 1px solid #ddd;
  border-top:5px solid$tab-color;
  border-bottom:5px solid $tab-color;
  padding: 0px !important;
  color: white;
}

nav > div a.nav-item.nav-link:hover,
nav > div a.nav-item.nav-link:focus
{
  border: none;
  background: $tab-color;
  color: #ffffff;
  border-radius:0;
  transition:background 0.20s linear;
}

.table {
  width: 100%;
  margin-bottom: 0rem;
}

.toolbar{
  padding: 10px;
}

.canvas{
  border:1px solid #343a40;
}

.hidden {
  display: none;
}

.hopfield, .kohonen, .boltzmann, .ann, .index, .art, .backpropagation, .article, .appendix, .gan, .cnn{

    margin-bottom: 100px;
    margin-top:10px;
    padding: 15px;
    background: rgba(255,255,255,0.9) !important;

    .title{
      font-size: 17px;
      font-weight: bold;
      padding: 20px;
    }

    table{
        border: 1px solid black;
        margin: 10px;
    }
    tr,td, th{
      border: 1px solid black;
      padding: 10px
    }

    th{
      background: #0c5460;
      color: white;
    }

    .content-0{
      background: white;
      color: #151313;
    }

    .content-1{
      background: #151313;
      color: white;
    }

    mjx-container[jax="SVG"][display="true"]{
      text-align: left;
    }

    .important{
      background: #f8f8c2;
      padding: 10px;
    }

    .content-dp{
      background: #c3c2c2;
      color: black;
    }

  .content-dpup{
    background: #ffff8b;
    color: black;
    font-weight: bold;
  }

  .content-green{
    background: #bcde04;
    color: black;
    font-weight: bold;
  }

  .content-evident{
    background: #ffff8b;
    color: black;
    font-weight: bold;
  }

  .content-ordinal{
    background: white;
    color: black;
    font-weight: bold;
  }

  .content-change{
    background:brown;
    font-weight: bold;
    color: white;
  }
}

.boltzmann{
  mjx-container[jax="SVG"][display="true"]{
    margin: 0 0;
  }
}

.ann, .art, .backpropagation, .article{
  .padding-50 {
    mjx-container[jax="SVG"][display="true"] {
      padding-left: 50px;
    }
  }
  .center {
    mjx-container[jax="SVG"][display="true"] {
      text-align: center;
    }
  }

  .padding50 {
      padding-left: 50px;
  }
}

.content {
  ol {
    counter-reset: item
  }

  li {
    display: block
  }

  li:before {
    content: counters(item, ".") ". ";
    counter-increment: item
  }
}

.navivator{
  background:#212529;
  padding:15px ;
  margin-right: -15px;
  margin-left: -15px;
  a{
    color: #c8ffff;
  }

  a:link {
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
    color:white;
  }

  a:active {
    text-decoration: none;
  }
}

.cnnimage{
  td{
    margin: 0px;
    padding: 2px 5px;
    font-family: monospace;
  }
}

.cell-mark{
  background: transparent;
  color:black;
}

.subtitle{
  font-size: 12px;
  color:#777777;
  text-decoration: none;
}

div > img {
  border: 1px solid #eeeeee;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  padding: 10px;
}

.index{
  .bg-card{
    background: $card-color;
  }

  .card{
    border-radius: 0 !important;
  }

  .card-title{
    color: #e9c46a;
  }

  .row{
    a:hover{
      text-decoration: none;
    }
  }
}

.index-page{
  margin-bottom: 100px;
  margin-top:10px;
  background: rgba(255,255,255,0.9) !important;

  .toc{
      font-weight: 700;

      a{
        color: #457b9d;
      }
      a:hover{
        text-decoration: none;
      }
  }
}

.home-index{
  background: transparent !important;
}

.max-height-300{
    //width: 500px;
    max-height: 350px;
    overflow-y: auto !important;
    overflow-x: auto !important;
    margin: 1em 0 1em 0 !important;
    position: relative !important;
    font-size: 1em !important;

}

table.num tbody {
  counter-reset: row-num;
}
table.num tbody tr {
  counter-increment: row-num;
}
table.num tbody tr td:first-child::before {
  content: counter(row-num) ". ";
}

.small-text{
  font-size: 8px;
}

.responsive-img {
  max-width: 100%;
  height: auto;
}